import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

export default function InvocationInsight({
  insight,
  pageCanvasRef,
  overlayRef,
  updateCorrection,
}) {
  const [editingCell, setEditingCell] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [hoveredCell, setHoveredCell] = useState(null);

  const getBoxSize = () => {
    const rect = pageCanvasRef.current?.getBoundingClientRect();
    return rect ? { width: rect.width, height: rect.height } : null;
  };

  const drawBoundingBox = (context, box) => {
    const boxSize = getBoxSize();
    if (!boxSize) return;

    const { width, height } = boxSize;
    if (context) {
      context.clearRect(
        box.x * width,
        box.y * height,
        box.width * width,
        box.height * height
      );
    }
  };

  const prepareOverlayCanvas = () => {
    if (overlayRef.current) {
      const context = overlayRef.current.getContext("2d");
      const { width, height } = getBoxSize();
      context.fillStyle = "rgba(0, 0, 0, 0.75)";
      context.fillRect(0, 0, width, height);

      return context;
    }
  };

  const rowUnhovered = () => {
    if (overlayRef.current) {
      const context = overlayRef.current.getContext("2d");
      const { width, height } = getBoxSize();
      context.clearRect(0, 0, width, height);
    }
  };

  let cellStyle = {
    backgroundColor: "white",
    color: "black",
  };

  const handleEditClick = (rowIndex, cellIndex, value) => {
    setEditingCell({ rowIndex, cellIndex });
    setEditValue(value);
  };

  const handleEditCancel = () => {
    setEditingCell(null);
    setEditValue("");
  };

  const handleEditAccept = (insightId, rowIndex, cellIndex) => {
    let cell;
    let childIndex;
    if (insight.type === "table") {
      cell = insight.content[rowIndex][cellIndex];
      childIndex = [rowIndex, cellIndex];
    } else if (insight.type === "form") {
      cell = insight.content[rowIndex];
      childIndex = [rowIndex];
    }
    updateCorrection(
      insightId,
      childIndex,
      editValue,
      cell.reference.boundingBox
    );
    setEditingCell(null);
    setEditValue("");
  };

  const renderCell = (cell, rowIndex, cellIndex) => {
    const isEditing =
      editingCell &&
      editingCell.rowIndex === rowIndex &&
      editingCell.cellIndex === cellIndex;

    const isHovered =
      hoveredCell &&
      hoveredCell.rowIndex === rowIndex &&
      hoveredCell.cellIndex === cellIndex;

    if (isEditing) {
      return (
        <>
          <TextField
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            size="small"
            fullWidth
            multiline
            minRows={1}
            maxRows={4}
            style={{ minWidth: "200px" }}
          />
          <IconButton
            onClick={() => handleEditAccept(insight.id, rowIndex, cellIndex)}
            size="small"
          >
            <CheckIcon />
          </IconButton>
          <IconButton onClick={handleEditCancel} size="small">
            <ClearIcon />
          </IconButton>
        </>
      );
    }

    return (
      <div
        onMouseEnter={() => setHoveredCell({ rowIndex, cellIndex })}
        onMouseLeave={() => setHoveredCell(null)}
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        {cell.value?.toString()}
        <IconButton
          onClick={() => handleEditClick(rowIndex, cellIndex, cell.value)}
          size="small"
          style={{
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.2s",
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  if (insight.type === "table") {
    const tableRowHovered = (row) => {
      const context = prepareOverlayCanvas();
      row.forEach((cell) =>
        drawBoundingBox(context, cell.reference.boundingBox)
      );
    };

    // Make sure these are sorted based on the y-coordinate
    const getMinY = (cells) => {
      return Math.min(...cells.map((cell) => cell.reference.boundingBox.y));
    };
    const indexedInsights = insight.content.map((item, index) => ({
      item,
      index,
    }));
    const sortedInsights = indexedInsights.sort(
      (a, b) => getMinY(a.item) - getMinY(b.item)
    );

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {sortedInsights.map(({ item: row, index }) => (
              <TableRow
                key={`row-${index}`}
                rowSpan={row.rowSpan}
                onMouseEnter={() => tableRowHovered(row)}
                onMouseLeave={rowUnhovered}
              >
                {row.map((cell, cellIndex) => {
                  let style = { ...cellStyle };
                  if (
                    cell.types.some((type) => [
                      "TABLE_TITLE",
                      "TABLE_FOOTER",
                      "TABLE_SECTION_TITLE",
                      "TABLE_SUMMARY",
                    ])
                  ) {
                    style = {
                      backgroundColor: "#6131FF",
                      color: "white",
                      textAlign: "center",
                    };
                  }

                  return (
                    <TableCell
                      key={`cell-${cellIndex}`}
                      colSpan={cell.columnSpan}
                      style={style}
                    >
                      {renderCell(cell, index, cellIndex)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (insight.type === "form") {
    const formRowHovered = (field) => {
      const context = prepareOverlayCanvas();
      drawBoundingBox(context, field.reference.boundingBox);
    };

    // Make sure these are sorted based on the y-coordinate
    const indexedInsights = insight.content.map((item, index) => ({
      item,
      index,
    }));
    const sortedInsights = indexedInsights.sort(
      (a, b) => a.item.reference.boundingBox.y - b.item.reference.boundingBox.y
    );

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedInsights.map(({ item: field, index }) => (
              <TableRow
                key={`${field.name}-${index}`}
                onMouseEnter={() => formRowHovered(field)}
                onMouseLeave={rowUnhovered}
              >
                <TableCell component="th" scope="row" style={cellStyle}>
                  <strong>{field.name}</strong>
                </TableCell>
                <TableCell style={cellStyle}>
                  {renderCell(field, index, null)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    const cellHovered = (insight, field) => {
      if (insight[field].reference.boundingBox) {
        const context = prepareOverlayCanvas();
        drawBoundingBox(context, insight[field].reference.boundingBox);
      }
    };

    const keys = Object.keys(insight.content[0]);
    // Make sure these are sorted based on the y-coordinate
    const getMinY = (fields) => {
      const boundingBox = fields[keys[0]].reference.boundingBox;
      return boundingBox ? boundingBox.y : Number.MAX_SAFE_INTEGER;
    };
    const sortedInsights = insight.content.sort(
      (a, b) => getMinY(a) - getMinY(b)
    );

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {keys.map((key, index) => {
                return (
                  <TableCell
                    key={`${key}-${index}`}
                    style={{ textTransform: "capitalize" }}
                  >
                    {key}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedInsights.map((insight, index) => (
              <TableRow key={`row-${index}`}>
                {keys.map((key, index) => {
                  return (
                    <TableCell
                      style={cellStyle}
                      key={`cell-${index}`}
                      onMouseEnter={() => cellHovered(insight, key)}
                      onMouseLeave={rowUnhovered}
                    >
                      {insight[key].value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
