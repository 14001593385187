import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { guruUtils } from "../common/Utils";
import GuruConsolePageLayout from "../common/GuruConsolePageLayout";
import TabPanel from "../common/TabPanel";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ReactJson from "react-json-view";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { darkColor } from "../common/Theme";
import InvocationInsight from "./InvocationInsight";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import LabelInvocation from "./LabelInvocation";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const maxWidth = 1024;
const maxHeight = 2048;

export default function AutomationInvocationPage() {
  let { automationId, invocationId } = useParams();
  const [automation, setAutomation] = useState(null);
  const [invocation, setInvocation] = useState(null);
  const [output, setOutput] = useState({});
  const [insights, setInsights] = useState([]);
  const [corrections, setCorrections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputFilePages, setInputFilePages] = useState();
  const [inputFilePageNum, setInputFilePageNum] = useState(0);
  const [tabIndex, setTabIndex] = React.useState(0);
  const pageCanvasRef = useRef(null);
  const overlayRef = useRef(null);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState(maxWidth);
  const [containerHeight, setContainerHeight] = useState(maxHeight);

  const mergeCorrections = (insights, corrections) => {
    return insights.map((insight) => {
      const insightCorrections = corrections.filter(
        (c) => c.parent_id === insight.id
      );

      if (insightCorrections.length === 0) {
        return insight;
      }

      const updatedContent = insight.content.map((item, rowIndex) => {
        if (insight.type === "table") {
          return item.map((cell, cellIndex) => {
            const correction = insightCorrections.find(
              (c) =>
                c.child_index[0] === rowIndex && c.child_index[1] === cellIndex
            );
            return correction ? { ...cell, value: correction.value } : cell;
          });
        } else if (insight.type === "form") {
          const correction = insightCorrections.find(
            (c) => c.child_index[0] === rowIndex
          );
          return correction ? { ...item, value: correction.value } : item;
        } else {
          console.error("Unknown insight type", insight.type);
        }
        return item;
      });

      return { ...insight, content: updatedContent };
    });
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchAutomation = async () => {
      try {
        const automationResponse = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${automationId}`,
          headers: await guruUtils.getAuthHeaders(),
        });
        setAutomation(automationResponse.data);
      } catch (error) {
        console.error("Error fetching data", error);
        setError(error);
      }
    };

    const fetchInvocation = async () => {
      try {
        const invocationResponse = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${automationId}/invocations/${invocationId}`,
          headers: await guruUtils.getAuthHeaders(),
        });
        setInvocation(invocationResponse.data);

        if (invocationResponse.data["output_url"]) {
          const outputResponse = await axios({
            url: invocationResponse.data["output_url"],
          });
          setOutput(outputResponse.data);
        }

        return invocationResponse.data;
      } catch (error) {
        console.error("Error fetching data", error);
        setError(error);
      }
    };

    const fetchCorrections = async (invocationResult) => {
      try {
        const correctionsResponse = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${automationId}/invocations/${invocationId}/files/${
            invocationResult.files[0].file_id
          }/corrections`,
          headers: await guruUtils.getAuthHeaders(),
        });
        setCorrections(correctionsResponse.data);
      } catch (error) {
        console.error("Error fetching corrections", error);
        setError(error);
      }
    };

    const fetchInsights = async (invocationResult) => {
      try {
        const insightsResponse = await axios({
          url: `https://${await guruUtils.apiDomain()}/automations/${automationId}/invocations/${invocationId}/files/${
            invocationResult.files[0].file_id
          }/insights`,
          headers: await guruUtils.getAuthHeaders(),
        });
        setInsights(
          insightsResponse.data.sort((insight0, insight1) => {
            return insight0.reference.boundingBox.y <
              insight1.reference.boundingBox.y
              ? -1
              : 1;
          })
        );
      } catch (error) {
        console.error("Error fetching data", error);
        setError(error);
      }
    };

    Promise.all([fetchAutomation(), fetchInvocation()]).then((results) => {
      const invocationResult = results[1];

      if (invocationResult && invocationResult.files.length > 0) {
        Promise.all([
          fetchInsights(invocationResult),
          fetchCorrections(invocationResult),
        ]).then(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }
    });
  }, [automationId, invocationId]);

  const formatOutput = (output) => {
    var isValidJson = true;
    var result;
    if (typeof output === "object") {
      result = output;
    } else {
      try {
        result = JSON.parse(output);
      } catch (e) {
        isValidJson = false;
      }
    }

    if (isValidJson && typeof result === "object") {
      return (
        <ReactJson
          src={result}
          name={null}
          collapsed={2}
          sortKeys={false}
          displayDataTypes={false}
        />
      );
    }
    // TODO: should we use markdown preview for this, too?
    return <Typography className={"has-linebreaks"}>{output}</Typography>;
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setInputFilePages(nextNumPages);
  }

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(Math.min(entry.contentRect.width, maxWidth));
      setContainerHeight(Math.min(entry.contentRect.height, maxHeight));
    }
  }, []);
  useResizeObserver(containerRef, {}, onResize);

  const rehypePlugins = [
    rehypeSanitize,
    // Remove the anchor links that are automatically added to the Markdown headings
    {
      rewrite: (node, index, parent) => {
        if (
          node.tagName === "a" &&
          parent &&
          /^h(1|2|3|4|5|6)/.test(parent.tagName)
        ) {
          parent.children = [parent.children[1]];
        }
      },
    },
  ];

  const updatePageNum = (newPageNum) => {
    newPageNum = Math.min(Math.max(0, newPageNum), inputFilePages - 1);
    setInputFilePageNum(newPageNum);
  };

  const updateCorrection = async (
    insightId,
    childIndex,
    newValue,
    boundingBox
  ) => {
    try {
      // Create a new correction object
      const newCorrection = {
        parent_id: insightId,
        child_index: childIndex,
        value: newValue,
        page: inputFilePageNum,
        boundingBox: boundingBox,
      };

      // Create a new list of corrections, either updating an existing one or adding a new one
      const updatedCorrections = corrections.map((c) =>
        c.parent_id === insightId &&
        c.child_index.join(",") === childIndex.join(",")
          ? newCorrection
          : c
      );
      if (
        !updatedCorrections.some(
          (c) =>
            c.parent_id === insightId &&
            c.child_index.join(",") === childIndex.join(",")
        )
      ) {
        updatedCorrections.push(newCorrection);
      }

      // Send the full list of corrections to the server
      const response = await axios({
        method: "POST",
        url: `https://${await guruUtils.apiDomain()}/automations/${automationId}/invocations/${invocationId}/files/${
          invocation.files[0].file_id
        }/corrections`,
        headers: await guruUtils.getAuthHeaders(),
        data: updatedCorrections,
      });

      // Update the corrections state with the response from the server
      setCorrections(updatedCorrections);
    } catch (error) {
      console.error("Error updating corrections", error);
      setError(error);
    }
  };

  if (isLoading) {
    return (
      <GuruConsolePageLayout p={2}>
        <CircularProgress />
      </GuruConsolePageLayout>
    );
  }

  return (
    <GuruConsolePageLayout>
      <Grid container>
        <Grid
          item
          xs={6}
          p={2}
          style={{ maxHeight: "100vh", overflow: "auto" }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <ButtonGroup variant="outlined">
              <Button onClick={() => updatePageNum(inputFilePageNum - 1)}>
                {"<"}
              </Button>
              <Button>Page {inputFilePageNum + 1}</Button>
              <Button onClick={() => updatePageNum(inputFilePageNum + 1)}>
                {">"}
              </Button>
            </ButtonGroup>
          </Box>
          {automation.input_doc_types.length === 0 && (
            <div style={{ height: "100%" }} data-color-mode="light">
              <MDEditor.Markdown
                source={invocation.input}
                style={{ whiteSpace: "pre-wrap" }}
                rehypePlugins={[rehypePlugins]}
              />
            </div>
          )}
          {automation.input_doc_types.length > 0 &&
            invocation.files[0].extension === "pdf" && (
              <Box ref={setContainerRef} style={{ cursor: "crosshair" }}>
                <Document
                  file={invocation.files[0].url}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={inputFilePageNum + 1}
                    width={containerWidth}
                    canvasRef={pageCanvasRef}
                  >
                    <canvas
                      ref={overlayRef}
                      width={
                        pageCanvasRef.current ? pageCanvasRef.current.width : 1
                      }
                      height={
                        pageCanvasRef.current ? pageCanvasRef.current.height : 1
                      }
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 100,
                      }}
                    />
                  </Page>
                </Document>
              </Box>
            )}
        </Grid>
        <Grid
          item
          xs={6}
          p={2}
          style={{ maxHeight: "100vh", overflow: "auto" }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
              <Tab style={{ color: darkColor }} label="Insights" />
              <Tab style={{ color: darkColor }} label="Output" />
            </Tabs>
          </Box>
          <TabPanel value={tabIndex} index={0}>
            {mergeCorrections(insights, corrections)
              .filter((insight) => insight.reference.page === inputFilePageNum)
              .map((insight) => (
                <Box key={`insight-${insight.id}`} pt={1}>
                  <InvocationInsight
                    insight={insight}
                    pageCanvasRef={pageCanvasRef}
                    overlayRef={overlayRef}
                    updateCorrection={updateCorrection}
                  />
                </Box>
              ))}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            {formatOutput(output)}
          </TabPanel>
        </Grid>
      </Grid>

      {overlayRef && <LabelInvocation canvasElement={overlayRef.current} />}
    </GuruConsolePageLayout>
  );
}
